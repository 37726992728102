// src/components/ChatIconButton.js
import React from 'react';
import { TbRobot } from 'react-icons/tb'; // Importa o ícone

const ChatIconButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="chat-icon-button">
      <TbRobot size={30} color="#fff" /> {/* Utiliza o ícone no botão */}
    </button>
  );
}

export default ChatIconButton;
