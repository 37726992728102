// src/components/formatClientData.js

// Função para converter o número do estado civil em texto
// const getEstadoCivil = (id) => {
//     switch(id) {
//       case 1:
//         return 'Solteiro';
//       case 2:
//         return 'Casado';
//       case 3:
//         return 'Viúvo';
//       default:
//         return 'Desconhecido';
//     }
//   };
  
  // Função para converter o número do tipo de participante em texto
  // const getTipoParticipante = (id) => {
  //   switch(id) {
  //     case 1:
  //       return 'Regime Tributário';
  //     case 2:
  //       return 'Regime Progressivo';
  //     default:
  //       return 'Desconhecido';
  //   }
  // };
  
  // Função para formatar o CPF
  // const formatCPF = (cpf) => {
  //   return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  // };
  
  // Função para formatar o valor monetário
  // const formatCurrency = (value) => {
  //   return `R$ ${parseFloat(value).toFixed(2)}`;
  // };
  
  // Formatar forma de pagamento
  // const formatPayment = (payment) => {
  //   return payment.charAt(0).toUpperCase() + payment.slice(1);
  // };
  
  // Função principal para formatar os dados do cliente
 // src/components/formatClientData.js

  // src/components/formatClientData.js

export const formatClientData = (data) => {
  const formatPhoneNumber = (number) => {
    return number.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
  };


  return ( <>
    <p>Dados do participante:</p>
    <p>Matrícula: {data.matricula}</p>
    <p>CPF: {data.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</p>
    <p>Nome: {data.nome}</p>
    <p>Nascimento: {new Date(data.data_nascimento).toLocaleDateString()}</p>
    <p>Sexo: {data.sexo}</p> 
    <p>Estado Civil: {data.id_estado_civil === 1 ? 'Solteiro' : data.id_estado_civil === 2 ? 'Casado' : 'Viúvo'}</p>
    <p>Telefone Celular: {formatPhoneNumber(data.celular)}</p>
    <p>Telefone Fixo: {formatPhoneNumber(data.fixo)}</p>
    <p>Email: {data.email}</p>
    <p>Email secundário: {data.email_secundario}</p>
    <p>Investimento mensal: {parseFloat(data.investimento_mensal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>  
    <p>Forma Pagamento: {data.forma_pagamento === 'debito' ? 'Débito' : data.forma_pagamento}</p>
    <p>Tipo Participante: {data.id_tipo_participante === 1 ? 'Regime Tributário' : 'Regime Progressivo'}</p>
    </>
  );
};

export const formatFinalOptionsMessage = () => {
  return (
    <>
      <p>Agora temos duas opções. Você pode realizar a adesão ao nosso plano, realizar novas simulações ou sair. O que deseja?</p>
      <p>1 – Realizar adesão</p>
      <p>2 – Nova simulação</p>
      <p>3 – Sair</p>
    </>
  );
};

export const formatOptionsMessage = () => {
  return (
    <>
      <p>Escolha uma das opções abaixo:</p>
      <p>1- Quero ver os meus dados pessoais</p>
      <p>2- Quero um extrato de Benefício</p>
      <p>3- Quero um extrato de Contribuição</p>
      <p>4- Quero simular a tributação para imposto de renda</p>
      <p>5- Quero a minha declaração de contribuição</p>
      <p>6- Sair</p>
    </>
  );
};

export const formatSimulationResult = (data) => {
  return (
    <>
      <p>Resultado da Simulação:</p>
      <p>Contribuição para este fundo: R${data.contrib_fundo.toFixed(2)}</p>
      <p>Contribuição para outras entidades: R${data.contrib_outras.toFixed(2)}</p>
      <p>Máximo permitido: R${data.max_permitido.toFixed(2)}</p>
      <p>Sugerimos uma contribuição adicional de R${data.sugestao_contribuicao_maxima.toFixed(2)} para conseguir o máximo de vantagem tributária.</p>
    </>
  );
};



  
  export default formatClientData;
  