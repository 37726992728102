// src/components/ChatSteps.js
import React from 'react';
import { TbRobot } from 'react-icons/tb'; // Ícone do bot
import { FaUser } from 'react-icons/fa'; // Ícone do usuário

const ChatSteps = ({ messages }) => {
  return (
    <div className="chat-steps">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`message-container ${message.sender === 'user' ? 'user' : 'bot'}`}
        >
          <div className="icon">
            {message.sender === 'bot' ? (
              <TbRobot size={24} color="#4a148c" />
            ) : (
              <FaUser size={24} color="#4a148c" />
            )}
          </div>
          <div className="message-bubble">
            {message.text}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ChatSteps;
