// src/utils/validationUtils.js

export const isValidDate = (dateString) => {
    // Verifica se a data está no formato dd/mm/aaaa
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  
    if (!regex.test(dateString)) {
      return false;
    }
  
    // Verifica se a data é válida (ex: não permite 31/02/2020)
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    return date.getDate() === day && date.getMonth() + 1 === month && date.getFullYear() === year;
  };
  
  export const isValidNumber = (input) => {
    return /^\d+$/.test(input);
};

// src/utils/validationUtils.js

export const isValidCPF = (cpf) => {
    // Verifica se o CPF está no formato xxx.xxx.xxx-xx
    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    if (!regex.test(cpf)) {
        return false;
    }

    // Remove os caracteres especiais do CPF
    cpf = cpf.replace(/[^\d]+/g, '');

    // Validação do CPF
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;
};

export const isValidName = (name) => {      //verifica se o nome não tem números
    const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    return regex.test(name);
  };



// Valida o formato do telefone fixo (ex: (11) 1234-5678)
// Valida o formato do telefone fixo (ex: (11) 1234-5678 ou 1112345678)
export const isValidTelefoneFixo = (telefoneFixo) => {
  const regex = /^(\(\d{2}\)\s?\d{4}-\d{4})|(\d{10})$/;
  return regex.test(telefoneFixo);
};

// Valida o formato do telefone celular (ex: (11) 91234-5678 ou 11912345678)
export const isValidTelefoneCelular = (telefoneCelular) => {
  const regex = /^(\(\d{2}\)\s?9\d{4}-\d{4})|(\d{11})$/;
  return regex.test(telefoneCelular);
};

  
  // Valida o formato do CEP (ex: 12345-678)
  export const isValidCEP = (cep) => {
    const regex = /^\d{5}-\d{3}$/;
    return regex.test(cep);
  };
  
  // src/utils/chatUtils.js

export const resetChat = (setMessages, setStep, setUserInput, setSimulacaoDados, setDadosPessoais) => {
  // Reseta as mensagens
  setMessages([
    { text: 'Bem-vindo ao Chatbot de Aposentadoria!', sender: 'bot' }
  ]);

  // Reseta as variáveis de estado
  setStep(1); // Volta ao primeiro step
  setUserInput(''); // Limpa a entrada do usuário
  setSimulacaoDados({}); // Limpa os dados da simulação
  setDadosPessoais({}); // Limpa os dados pessoais

  // Adiciona uma mensagem inicial de saudação (opcional)
  setTimeout(() => {
    setMessages(prevMessages => [
      ...prevMessages,
      { text: 'Como posso te ajudar hoje? (1 - Já tenho cadastro | 2 - Fazer simulação novamente)', sender: 'bot' }
    ]);
  }, 1000);
};

export const isValidEmail = (email) => {
  // Verifica se o email tem o formato "texto@texto.texto"
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
