// src/components/ChatStructure.js
import React, { useState, useEffect, useRef } from 'react';
import ChatIconButton from './ChatIconButton';
import ChatSteps from './ChatSteps';
import { formatClientData, formatSimulationResult, formatOptionsMessage, formatFinalOptionsMessage, } from './formatClientData';
import { isValidCPF, isValidName, isValidNumber, isValidDate, isValidTelefoneFixo, isValidTelefoneCelular, isValidCEP,} from './utils/validationUtils';


const ChatStructure = () => {
  const { REACT_APP_API_URL } = process.env;
  const [isOpen, setIsOpen] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState([
    { text: 'Bem-vindo ao ChatPrev', sender: 'bot' }
  ]);
  const [step, setStep] = useState(1);
  const [matricula, setMatricula] = useState('');
  const [simulacao, setSimulacao] = useState(null);
  const [dadosPessoais, setDadosPessoais] = useState({});
  const [simulacaoDados, setSimulacaoDados] = useState({});
  const chatContentRef = useRef(null);
  const currentYear = new Date().getFullYear(); // definindo ano

  const handleExit = () => {
    setMessages([
      { text: 'Atendimento encerrado. Obrigado por utilizar o ChatPrev!', sender: 'bot' }
    ]);
    setTimeout(() => {
      setIsOpen(false); // Fecha o chat após uma pequena pausa
    }, 2000);
    setStep(1); // Reseta o step para o início caso o chat seja reaberto
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const scrollToBottom = () => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const delay1 = setTimeout(() => {
      setMessages(prevMessages => [
        ...prevMessages,
        { text: 'Você já é participante? 1- Sim 2- Não', sender: 'bot' }
      ]);
    }, 2000);

    return () => clearTimeout(delay1);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const handleSend = () => {
    if (!userInput.trim()) return;

    // mascarando a senha
    const displayedInput = (step === 2) ? '*'.repeat(userInput.length) : userInput;


    setMessages(prevMessages => [
      ...prevMessages,
      { text: displayedInput, sender: 'user' }
    ]);
    

    if (step === 1) {
      if (userInput === '1') {
        setTimeout(() => {
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Que ótimo! Então me informe sua matrícula.', sender: 'bot' }
          ]);
          setStep(1.5); // Novo step para aguardar matrícula
        }, 2000);
      } else if (userInput === '2') {
        // Usuário não tem cadastro, inicia simulação passo a passo
        setTimeout(() => {
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Olá. Você ainda não é participante do nosso fundo. Podemos simular como seria a sua aposentadoria se investisse conosco ou você pode voltar ao menu inicial.', sender: 'bot' },
            { text: 'Vamos começar a simulação de contribuição. Qual é o seu nome?', sender: 'bot' }
          ]);
          setStep(7); // Inicia o passo a passo da simulação
        }, 2000);
      } else {
        setTimeout(() => {
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Opção inválida. Por favor, responda com 1- Sim ou 2- Não.', sender: 'bot' },
            { text: 'Você já tem cadastro? 1- Sim 2- Não', sender: 'bot' }
          ]);
        }, 2000);
      }
    } else if (step === 1.5) {
      setMatricula(userInput); // Armazena a matrícula
      setMessages(prevMessages => [
        ...prevMessages,
        { text: 'Agora, por favor, insira sua senha.', sender: 'bot' }
      ]);
      setStep(2); // Próximo step para coletar a senha
    } else if (step === 2) {
      const senha = userInput; // Armazena a senha, que fica oculta na interface
      setMessages(prevMessages => [
        ...prevMessages,
        { text: 'Recebi as suas credenciais. Aguarde um momento que vou verificar os seus dados.', sender: 'bot' }
      ]);

      setTimeout(() => {
        // Realiza a verificação da matrícula e senha
        fetch(`${REACT_APP_API_URL}/validar_matricula`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ matricula, senha }) // Envia matrícula e senha
        })
          .then(response => response.json())
          .then(data => {
            if (data.valido) {
              setMessages(prevMessages => [
                ...prevMessages,
                { text: `Bem-vindo(a) ${data.nome}. Como posso te ajudar?`, sender: 'bot' },
                { text: formatOptionsMessage(), sender: 'bot' }
              ]);
              setStep(3);
            } else {
              setMessages(prevMessages => [
                ...prevMessages,
                { text: 'Matrícula ou senha incorreta. Por favor, tente novamente.', sender: 'bot' },
                { text: 'Por favor, insira sua matrícula novamente.', sender: 'bot' }
              ]);
              setStep(1.5); // Volta para pedir a matrícula novamente
            }
          })
          .catch((error) => {
            console.error('Erro ao validar matrícula e senha:', error);
            setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Erro ao validar matrícula e senha. Tente novamente mais tarde.', sender: 'bot' }
            ]);
            setStep(1.5); // Volta para pedir a matrícula novamente
          });
      }, 2000); // Delay para dar tempo ao usuário de ler a mensagem anterior
    }
    else if (step === 3) {
      switch (userInput) {
        case '1':
          fetch(`${REACT_APP_API_URL}/apresenta_dados/${matricula}`)
            .then(response => response.json())
            .then(data => {
              const formattedMessage = formatClientData(data);
              setMessages(prevMessages => [
                ...prevMessages,
                { text: formattedMessage, sender: 'bot' }
              ]);
              handleNextStep();
            });
          break;
        case '2':
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Gerando arquivo PDF...', sender: 'bot' }
          ]);
          fetch(`${REACT_APP_API_URL}/extrato_beneficio/${matricula}`, {
            method: 'GET',
          })
            .then(response => response.blob())
            .then(blob => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `extrato_beneficio_${matricula}.pdf`);
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              setMessages(prevMessages => [
                ...prevMessages,
                { text: 'O Extrato de Benefício foi gerado e baixado.', sender: 'bot' }
              ]);
              handleNextStep();
            });
          break;
        case '3':
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Por favor, insira o mês e ano de início (mm-aaaa):', sender: 'bot' }
          ]);
          setStep(4); // Aguardando mês e ano de início
          break;
        case '4':
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'O simulador tributário fornecerá uma ajuda para maximizar a dedução permitida no seu imposto de renda. Por favor, informe a sua renda bruta anual, contribuições realizadas a este fundo, contribuições realizadas a outras entidades de previdência (ex: 5000,50 ; 2000,25 ; 60)', sender: 'bot' }
          ]);
          setStep(5); // Aguardando valores para simulação
          break;
        case '5':
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Por favor, insira o mês e ano de início para a declaração de contribuição (mm-aaaa):', sender: 'bot' }
          ]);
          setStep(6); // Aguardando mês e ano de início para declaração
          break;
        case '6':
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Atendimento encerrado. Obrigado!', sender: 'bot' }
          ]);
          setStep(1); // Reinicia ou define o passo de encerramento
          break;
        default:
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Opção inválida. Por favor, escolha entre 1-6.', sender: 'bot' }
          ]);
      }
    } else if (step === 4) {
      const [mes_inicio, ano_inicio] = userInput.split('-');
      setMessages(prevMessages => [
        ...prevMessages,
        { text: 'Por favor, insira o mês e ano de fim (mm-aaaa):', sender: 'bot' }
      ]);
      setSimulacaoDados({ ...simulacaoDados, mes_inicio, ano_inicio });
      setStep(4.5);
    } else if (step === 4.5) {
      const [mes_fim, ano_fim] = userInput.split('-');
      const { mes_inicio, ano_inicio } = simulacaoDados;
      setMessages(prevMessages => [
        ...prevMessages,
        { text: 'Gerando arquivo PDF...', sender: 'bot' }
      ]);
      fetch(`${REACT_APP_API_URL}/extrato_contribuicao/${matricula}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ano_inicio, mes_inicio, ano_fim, mes_fim })
      })
        .then(response => {
          if (!response.ok) {
            // MANIPULAÇÃO DOS TIPOS DE ERROS MAIS COMUNS
            return response.json().then(errorData => {
              if (response.status === 400) {
                setMessages(prevMessages => [
                  ...prevMessages,
                  { text: `Erro: ${errorData.error}`, sender: 'bot' }
                ]);
              } else if (response.status === 404) {
                setMessages(prevMessages => [
                  ...prevMessages,
                  { text: `Nenhuma contribuição encontrada para o período especificado.`, sender: 'bot' }
                ]);
              } else {
                setMessages(prevMessages => [
                  ...prevMessages,
                  { text: `Erro inesperado: ${errorData.error}`, sender: 'bot' }
                ]);
              }
            });
          } else {
            // Se a resposta estiver OK (200), processa o PDF
            return response.blob();
          }
        })
        .then(blob => {
          if (blob) {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `extrato_contribuicao_${matricula}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            setMessages(prevMessages => [
              ...prevMessages,
              { text: 'O Extrato de Contribuição foi gerado e baixado.', sender: 'bot' }
            ]);
            setStep(3); // Volta para as opções
            handleNextStep();
          }
        })
        .catch(error => {
          console.error('Erro ao tentar gerar o extrato:', error);
          setMessages(prevMessages => [
            ...prevMessages,
            { text: `Erro ao tentar gerar o extrato: ${error.message}`, sender: 'bot' }
          ]);
        });
      } else if (step === 5) {
        // Verifica o input do usuário e permite ; como separador e , como decimal
        const values = userInput.split(';').map(value => value.trim().replace(',', '.'));
        
        // Valida se há exatamente 3 valores
        if (values.length !== 3) {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: 'Erro: Por favor, insira os três valores no formato: renda bruta; contribuição fundo; outras contribuições. Exemplo: 5000,25; 200,10; 5,25', sender: 'bot' }
            ]);
            return; // Sai da função sem avançar para o próximo step
        }
    
        // Extrai os valores e converte para formato numérico
        const [renda_bruta, contrib_fundo, contrib_outras] = values.map(value => parseFloat(value));
    
        // Verifica se todos os valores foram convertidos corretamente para números
        if (isNaN(renda_bruta) || isNaN(contrib_fundo) || isNaN(contrib_outras)) {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: 'Erro: Certifique-se de inserir números válidos no formato brasileiro. Exemplo: 5000,25; 200,10; 5,25', sender: 'bot' }
            ]);
            return;
        }
    
        // Realiza a chamada para o endpoint com os valores formatados
        fetch(`${REACT_APP_API_URL}/simular_tributacao/${matricula}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ renda_bruta, contrib_fundo, contrib_outras })
        })
        .then(response => response.json())
        .then(data => {
            const formattedSimulation = formatSimulationResult(data);
            setMessages(prevMessages => [
                ...prevMessages,
                { text: formattedSimulation, sender: 'bot' }
            ]);
            setStep(3); // Volta para as opções
            handleNextStep();
        })
        .catch(error => {
            console.error('Erro na simulação de tributação:', error);
            setMessages(prevMessages => [
                ...prevMessages,
                { text: 'Erro ao realizar a simulação. Tente novamente mais tarde.', sender: 'bot' }
                ]);
            });
        
          } else if (step === 6) {
        
            // Validação do mês e ano de início
            const [mes_inicio, ano_inicio] = userInput.split('-');
        
            const isMonthValid = mes_inicio >= '01' && mes_inicio <= '12';
            const isYearValid = ano_inicio <= currentYear.toString();
        
            if (isMonthValid && isYearValid) {
                setSimulacaoDados({ ...simulacaoDados, mes_inicio, ano_inicio });
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Por favor, insira o mês e ano de fim para a declaração (mm-aaaa):', sender: 'bot' }
                ]);
                setStep(6.5);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Erro: Por favor, insira uma data válida. O mês deve estar entre 01 e 12 e o ano não pode ser superior ao ano atual. insira o mês e ano de início para a declaração de contribuição (mm-aaaa):', sender: 'bot' }
                ]);
            }
        
          } else if (step === 6.5) {
            // Validação do mês e ano de fim
            const [mes_fim, ano_fim] = userInput.split('-');
        
            const isMonthValid = mes_fim >= '01' && mes_fim <= '12';
            const isYearValid = ano_fim <= currentYear.toString();
        
            if (isMonthValid && isYearValid) {
                const { mes_inicio, ano_inicio } = simulacaoDados;
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Gerando arquivo PDF...', sender: 'bot' }
                ]);
                fetch(`${REACT_APP_API_URL}/declaracao_contribuicao`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ matricula, ano_inicio, mes_inicio, ano_fim, mes_fim })
                })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(errorData => {
                            if (response.status === 400) {
                                setMessages(prevMessages => [
                                    ...prevMessages,
                                    { text: `Erro: ${errorData.error}`, sender: 'bot' }
                                ]);
                            } else if (response.status === 404) {
                                setMessages(prevMessages => [
                                    ...prevMessages,
                                    { text: 'Nenhuma contribuição encontrada para a matrícula fornecida. O que deseja fazer agora?', sender: 'bot' },
                                    { text: '1 - Tentar outra data', sender: 'bot' },
                                    { text: '2 - Sair', sender: 'bot' }
                                ]);
                                setStep(6.6); // Define um novo step para lidar com essa escolha
                            } else {
                                setMessages(prevMessages => [
                                    ...prevMessages,
                                    { text: `Erro inesperado: ${errorData.error}`, sender: 'bot' }
                                ]);
                            }
                        });
                    } else {
                        return response.blob();
                    }
                })
                .then(blob => {
                    if (blob) {
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `declaracao_contribuicao_${matricula}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
        
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { text: 'A Declaração de Contribuição foi gerada e baixada.', sender: 'bot' }
                        ]);
                        setStep(3); // Volta para as opções
                        handleNextStep();
                    }
                })
                .catch(error => {
                    console.error('Erro ao tentar gerar a declaração:', error);
                });
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Erro: Por favor, insira uma data válida. O mês deve estar entre 01 e 12 e o ano não pode ser superior ao ano atual.', sender: 'bot' }
                ]);
            }
        } else if (step === 6.6) {
            // Tratamento da resposta após o usuário escolher entre tentar outra data ou sair
            if (userInput === '1') {
                // Reinicia o processo para inserir uma nova data
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Por favor, insira o mês e ano de início para a declaração de contribuição (mm-aaaa):', sender: 'bot' }
                ]);
                setStep(6); // Volta ao passo de início da data
            } else if (userInput === '2') {
                // Encerra o atendimento
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Atendimento encerrado.', sender: 'bot' }
                ]);
                setStep(1); // Reseta para o início
            } else {
                // Mensagem de erro para entrada inválida
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Opção inválida. Por favor, escolha uma das opções: 1 - Tentar outra data ou 2 - Sair.', sender: 'bot' }
                ]);
            }
        
            
      } else if (step === 7) {

        // Início da simulação passo a passo
        if (!simulacaoDados['Nome']) {
            setSimulacaoDados({ ...simulacaoDados, 'Nome': userInput });
            setMessages(prevMessages => [
                ...prevMessages,
                { text: 'Qual é a sua data de nascimento? (dd/mm/aaaa)', sender: 'bot' }
            ]);
        } else if (!simulacaoDados['Data de Nascimento']) {
            if (isValidDate(userInput)) {
                setSimulacaoDados({ ...simulacaoDados, 'Data de Nascimento': userInput });
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Com que idade deseja se aposentar?', sender: 'bot' }
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Data de nascimento inválida. Por favor, insira uma data válida no formato dd/mm/aaaa.', sender: 'bot' }
                ]);
            }
        } else if (!simulacaoDados['Idade Aposentadoria']) {
            if (isValidNumber(userInput)) {
                setSimulacaoDados({ ...simulacaoDados, 'Idade Aposentadoria': userInput });
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Por quantos anos deseja receber o benefício?', sender: 'bot' }
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Idade inválida. Por favor, insira um número válido.', sender: 'bot' }
                ]);
            }
        } else if (!simulacaoDados['Anos Beneficio']) {
            if (isValidNumber(userInput)) {
                setSimulacaoDados({ ...simulacaoDados, 'Anos Beneficio': userInput });
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Digite sua renda bruta mensal:', sender: 'bot' }
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Anos de benefício inválido. Por favor, insira um número válido.', sender: 'bot' }
                ]);
            }
        } else if (!simulacaoDados['Renda Mensal']) {
            if (isValidNumber(userInput)) {
                setSimulacaoDados({ ...simulacaoDados, 'Renda Mensal': userInput });
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Com quanto pode contribuir mensalmente?', sender: 'bot' }
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Renda mensal inválida. Por favor, insira um número válido.', sender: 'bot' }
                ]);
            }
        } else if (!simulacaoDados['Contribuição Mensal']) {
            if (isValidNumber(userInput)) {
                setSimulacaoDados({ ...simulacaoDados, 'Contribuição Mensal': userInput });
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Deseja realizar um aporte inicial? (Digite o valor ou 0 para nenhum)', sender: 'bot' }
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Contribuição mensal inválida. Por favor, insira um número válido.', sender: 'bot' }
                ]);
            }
        } else if (!simulacaoDados['Aporte Inicial']) {
            if (isValidNumber(userInput)) {
                setSimulacaoDados({ ...simulacaoDados, 'Aporte Inicial': userInput });
                fetch(`${REACT_APP_API_URL}/simula_contribuicao`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(simulacaoDados)
                })
                    .then(response => response.json())
                    .then(data => {
                        const beneficioEstimado = data['Beneficio Estimado'];
                        const idadeAposentadoria = simulacaoDados['Idade Aposentadoria'];
                        const contribuicaoMensal = simulacaoDados['Contribuição Mensal'];
                        const anosBeneficio = simulacaoDados['Anos Beneficio'];
    
                        delete data['Beneficio Estimado'];
    
                        setSimulacao({ ...data, beneficioEstimado });
    
                        const formattedBenefitMessage = `Se você contribuir com R$ ${parseFloat(contribuicaoMensal).toLocaleString('pt-BR', { minimumFractionDigits: 2 })} até atingir ${idadeAposentadoria} anos, poderá contar com um benefício mensal de R$ ${parseFloat(beneficioEstimado).toLocaleString('pt-BR', { minimumFractionDigits: 2 })} por ${anosBeneficio} anos.`;
    
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { text: formattedBenefitMessage, sender: 'bot' },
                            { text: formatFinalOptionsMessage(), sender: 'bot' }
                        ]);
                        setStep(8); // Próximo passo para adesão
                    })
                    .catch(error => {
                        console.error('Erro ao tentar simular a contribuição:', error);
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { text: 'Erro ao tentar simular a contribuição.', sender: 'bot' }
                        ]);
                    });
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: 'Valor do aporte inicial inválido. Por favor, insira um número válido.', sender: 'bot' }
                ]);
        }
      }
    } else if (step === 8) {
      if (userInput === '1') {
        // Código para realizar adesão
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Ótimo! Vamos seguir para a adesão. Qual é o seu CPF? (ex: xxx.xxx.xxx-xx)', sender: 'bot' }
        ]);
        setStep(9); // Próximo passo para inserção de dados pessoais
      } else if (userInput === '2') {
        // Código para iniciar uma nova simulação
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Vamos iniciar uma nova simulação. Qual é o seu nome?', sender: 'bot' }
        ]);
        setStep(7); // Volta para o passo de simulação
      } else if (userInput === '3') {
        // Chama a função handleExit para sair do chat
        handleExit();
      } else {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Opção inválida. Por favor, escolha uma opção válida.', sender: 'bot' }
        ]);
      }

    } else if (step === 9) {
      if (!dadosPessoais.CPF) {
          if (isValidCPF(userInput)) {
              setDadosPessoais({ ...dadosPessoais, CPF: userInput });
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Qual é o seu nome completo?', sender: 'bot' }
              ]);
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'CPF inválido. Por favor, insira um CPF válido no formato xxx.xxx.xxx-xx.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.nome) {
          if (isValidName(userInput)) {
              setDadosPessoais({ ...dadosPessoais, nome: userInput });
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Qual é a sua data de nascimento? (dd/mm/aaaa)', sender: 'bot' }
              ]);
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Nome inválido. Por favor, insira um nome válido contendo apenas letras.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.dataNascimento) {
          if (isValidDate(userInput)) {
              setDadosPessoais({ ...dadosPessoais, dataNascimento: userInput });
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Qual é o seu sexo?', sender: 'bot' }
              ]);
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Data de nascimento inválida. Por favor, insira uma data válida no formato dd/mm/aaaa.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.sexo) {
          setDadosPessoais({ ...dadosPessoais, sexo: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Qual é o seu telefone fixo? (ex: (61) 3303-4671 ou 6133034671)', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.telefoneFixo) {
          if (isValidTelefoneFixo(userInput)) {
              setDadosPessoais({ ...dadosPessoais, telefoneFixo: userInput });
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Qual é o seu celular? (ex:  (61) 91234-6548 ou 61912346548 )', sender: 'bot' }
              ]);
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Telefone fixo inválido. Por favor, insira um telefone válido no formato (XX) XXXX-XXXX.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.celular) {
          if (isValidTelefoneCelular(userInput)) {
              setDadosPessoais({ ...dadosPessoais, celular: userInput });
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Qual é o seu email?', sender: 'bot' }
              ]);
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Celular inválido. Por favor, insira um celular válido no formato (XX) 9XXXX-XXXX.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.email) {
          if (/\S+@\S+\.\S+/.test(userInput)) {  // Validação básica de e-mail
              setDadosPessoais({ ...dadosPessoais, email: userInput });
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Qual é o seu CEP? (ex: 01001-000)', sender: 'bot' }
              ]);
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Email inválido. Por favor, insira um email válido no formato exemplo@dominio.com.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.CEP) {
          if (isValidCEP(userInput)) {
              setDadosPessoais({ ...dadosPessoais, CEP: userInput });
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Qual é o seu logradouro?', sender: 'bot' }
              ]);
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'CEP inválido. Por favor, insira um CEP válido no formato XXXXX-XXX.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.logradouro) {
          setDadosPessoais({ ...dadosPessoais, logradouro: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Qual é o número?', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.numero) {
          setDadosPessoais({ ...dadosPessoais, numero: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Qual é o complemento?', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.complemento) {
          setDadosPessoais({ ...dadosPessoais, complemento: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Qual é o bairro?', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.bairro) {
          setDadosPessoais({ ...dadosPessoais, bairro: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Qual é a cidade?', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.cidade) {
          setDadosPessoais({ ...dadosPessoais, cidade: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Qual é a UF (estado)?', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.uf) {
          setDadosPessoais({ ...dadosPessoais, uf: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Você é uma Pessoa Politicamente Exposta (PPE)? (1- Sim, 2- Não)', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.PPE) {
          if (userInput === '1' || userInput === '2') {
              setDadosPessoais({ ...dadosPessoais, PPE: userInput === '1' ? 'Sim' : 'Não' });
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Você reside no exterior? (1- Sim, 2- Não)', sender: 'bot' }
              ]);
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Opção inválida. Por favor, responda com 1 para Sim ou 2 para Não.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.residenteExterior) {
          if (userInput === '1' || userInput === '2') {
              setDadosPessoais({ ...dadosPessoais, residenteExterior: userInput === '1' ? 'Sim' : 'Não' });
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Como deseja pagar a contribuição? (1- Boleto, 2- Débito em conta)', sender: 'bot' }
              ]);
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Opção inválida. Por favor, responda com 1 para Sim ou 2 para Não.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.formaPagamento) {
          if (userInput === '1' || userInput === '2') {
              setDadosPessoais({ ...dadosPessoais, formaPagamento: userInput });
              if (userInput === '2') {
                  setMessages(prevMessages => [
                      ...prevMessages,
                      { text: 'Digite o banco:', sender: 'bot' }
                  ]);
              } else {
                  setMessages(prevMessages => [
                      ...prevMessages,
                      { text: 'Informe o regime tributário: (1- Regressivo, 2- Progressivo, 3- Quero optar depois)', sender: 'bot' }
                  ]);
                  setStep(10); // Pula para o próximo passo
              }
          } else {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'Opção inválida. Por favor, responda com 1 para Boleto ou 2 para Débito em conta.', sender: 'bot' }
              ]);
          }
      } else if (!dadosPessoais.banco) {
          setDadosPessoais({ ...dadosPessoais, banco: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Digite a agência:', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.agencia) {
          setDadosPessoais({ ...dadosPessoais, agencia: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Digite a conta:', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.conta) {
          setDadosPessoais({ ...dadosPessoais, conta: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Digite o dígito:', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.digito) {
          setDadosPessoais({ ...dadosPessoais, digito: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Digite o tipo de conta (Ex: Conta Corrente):', sender: 'bot' }
          ]);
      } else if (!dadosPessoais.tipoConta) {
          setDadosPessoais({ ...dadosPessoais, tipoConta: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Informe o regime tributário: (1- Regressivo, 2- Progressivo, 3- Quero optar depois)', sender: 'bot' }
          ]);
          setStep(10); // Próximo passo
      }
    } else if (step === 10) {
      if (!dadosPessoais.regimeTributario) {
          setDadosPessoais({ ...dadosPessoais, regimeTributario: userInput });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Informe o primeiro beneficiário no seguinte formato: CPF,Nome,Parentesco,Porcentagem.', sender: 'bot' }
          ]);
      } else {
          const beneficiarios = dadosPessoais.beneficiarios || [];
          const [CPF, nome, parentesco, porcentagem] = userInput.split(',');
  
          // Verificação do CPF
          if (!isValidCPF(CPF.trim())) {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: 'CPF inválido. Por favor, insira um CPF válido no formato xxx.xxx.xxx-xx.', sender: 'bot' }
              ]);
          } else {
              const porcentagemFloat = parseFloat(porcentagem);
              const porcentagemAcumulada = beneficiarios.reduce((acc, ben) => acc + parseFloat(ben.porcentagem), 0) + porcentagemFloat;
  
              if (porcentagemAcumulada > 100) {
                  setMessages(prevMessages => [
                      ...prevMessages,
                      { text: 'A porcentagem máxima é de 100%.', sender: 'bot' },
                      { text: 'Digite novamente os dados do beneficiário com uma porcentagem válida.', sender: 'bot' }
                  ]);
              } else {
                  beneficiarios.push({ CPF, nome, parentesco, porcentagem: porcentagemFloat.toFixed(2) });
                  setDadosPessoais({ ...dadosPessoais, beneficiarios });
  
                  if (porcentagemAcumulada === 100) {
                      // Enviar a lista de beneficiários em mensagens separadas
                      const listaMensagens = beneficiarios.map((ben, index) => ({
                          text: `${index + 1}. ${ben.CPF}, ${ben.nome}, ${ben.parentesco}, ${ben.porcentagem}%`,
                          sender: 'bot'
                      }));
  
                      setMessages(prevMessages => [
                          ...prevMessages,
                          ...listaMensagens, // Adiciona as mensagens dos beneficiários
                          { text: 'Dados dos beneficiários completados. Deseja corrigir algum beneficiário? (1- Sim, 2- Não)', sender: 'bot' }
                      ]);
                      setStep(14); // Passo para confirmação e correção
                  } else {
                      setMessages(prevMessages => [
                          ...prevMessages,
                          { 
                              text: `A soma das porcentagens dos beneficiários ainda não é 100% (atualmente está em ${porcentagemAcumulada.toFixed(2)}%). Deseja adicionar outro beneficiário? (1 para Sim, 2 para Não)`, 
                              sender: 'bot' 
                          }
                      ]);
                      setStep(12); // Decidir se adiciona outro beneficiário
                  }
              }
          }
      }
  } else if (step === 12) {
      if (userInput === '1') {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Digite os dados do próximo beneficiário no formato: CPF,Nome,Parentesco,Porcentagem.', sender: 'bot' }
          ]);
          setStep(10); // Volta para o passo de adicionar beneficiário
      } else if (userInput === '2') {
          const porcentagemAcumulada = dadosPessoais.beneficiarios.reduce((acc, ben) => acc + parseFloat(ben.porcentagem), 0);
  
          if (porcentagemAcumulada < 100) {
              setMessages(prevMessages => [
                  ...prevMessages,
                  { text: `A soma das porcentagens dos beneficiários é de ${porcentagemAcumulada.toFixed(2)}%. A soma precisa ser exatamente 100% para concluir o cadastro.`, sender: 'bot' },
                  { text: 'O que deseja fazer: 1 - Adicionar outro beneficiário, 2 - Editar beneficiário existente, 3 - Sair', sender: 'bot' }
              ]);
              setStep(17); // Passo para escolha de ação adicional
          }
      }
  } else if (step === 14) { // Confirmação de dados dos beneficiários
      if (userInput === '1') {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Digite o número do beneficiário que deseja corrigir (com base na ordem que você adicionou):', sender: 'bot' }
          ]);
          setStep(15); // Passo para identificar qual beneficiário corrigir
      } else if (userInput === '2') {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Confirme os dados inseridos: (1- Sim, 2- Não)', sender: 'bot' }
          ]);
          setStep(11); // Próximo passo para confirmação e download
      } else {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Opção inválida. Por favor, escolha uma opção válida.', sender: 'bot' }
          ]);
      }
  } else if (step === 17) { // Opções após a verificação dos 100% não atingir
      if (userInput === '1') {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Digite os dados do próximo beneficiário no formato: CPF,Nome,Parentesco,Porcentagem.', sender: 'bot' }
          ]);
          setStep(10); // Volta para o passo de adicionar beneficiário
      } else if (userInput === '2') {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Digite o número do beneficiário que deseja corrigir (com base na ordem que você adicionou):', sender: 'bot' }
          ]);
          setStep(15); // Passo para identificar qual beneficiário corrigir
      } else if (userInput === '3') {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Atendimento encerrado.', sender: 'bot' }
          ]);
          setStep(1); // Encerra o atendimento
      } else {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Opção inválida. Por favor, escolha uma opção válida.', sender: 'bot' }
          ]);
      }
  } else if (step === 15) { // Passo para identificar qual beneficiário corrigir
      const index = parseInt(userInput) - 1;
      if (index >= 0 && index < dadosPessoais.beneficiarios.length) {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Digite os novos dados do beneficiário no formato: CPF,Nome,Parentesco,Porcentagem.', sender: 'bot' }
          ]);
          setStep(16); // Passo para corrigir o beneficiário
          setDadosPessoais({ ...dadosPessoais, beneficiarioIndex: index });
      } else {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Número inválido. Por favor, tente novamente.', sender: 'bot' }
          ]);
      }
  } else if (step === 16) {
      const beneficiarios = [...dadosPessoais.beneficiarios];
      const index = dadosPessoais.beneficiarioIndex;
      const [CPF, nome, parentesco, porcentagem] = userInput.split(',');
      const porcentagemFloat = parseFloat(porcentagem);
  
      const porcentagemAcumulada = beneficiarios.reduce((acc, ben, i) => i !== index ? acc + parseFloat(ben.porcentagem) : acc, 0) + porcentagemFloat;
  
      if (porcentagemAcumulada > 100) {
          setMessages(prevMessages => [
              ...prevMessages,
              { text: `A porcentagem máxima é de 100%.`, sender: 'bot' },
              { text: 'Digite novamente os dados do beneficiário com uma porcentagem válida.', sender: 'bot' }
          ]);
      } else {
          beneficiarios[index] = { CPF, nome, parentesco, porcentagem: porcentagemFloat.toFixed(2) };
          setDadosPessoais({ ...dadosPessoais, beneficiarios });
          setMessages(prevMessages => [
              ...prevMessages,
              { text: 'Dados do beneficiário corrigidos com sucesso!', sender: 'bot' },
              { text: 'Deseja corrigir outro beneficiário? (1- Sim, 2- Não)', sender: 'bot' }
          ]);
          setStep(14);
      }
    } else if (step === 11) {
      if (userInput === '1') {
        fetch(`${REACT_APP_API_URL}/gerar_pdf`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ dados: dadosPessoais, simulacao: simulacao })  // Enviar dados pessoais e simulação
        })
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `proposta_adesao.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
    
            setMessages(prevMessages => [
              ...prevMessages,
              { text: 'PDF gerado e baixado com sucesso!', sender: 'bot' }
            ]);
            setStep(1); // Volta ao início
          })
          .catch(error => {
            console.error('Erro ao gerar o PDF:', error);
            setMessages(prevMessages => [
              ...prevMessages,
              { text: `Erro ao gerar o PDF: ${error.message}`, sender: 'bot' }
            ]);
          });
      } else if (userInput === '2') {
        const options = [
          { text: 'CPF', step: 9 },
          { text: 'Nome', step: 9 },
          { text: 'Data de Nascimento', step: 9 },
          { text: 'Sexo', step: 9 },
          { text: 'Telefone Fixo', step: 9 },
          { text: 'Celular', step: 9 },
          { text: 'Email', step: 9 },
          { text: 'CEP', step: 9 },
          { text: 'Logradouro', step: 9 },
          { text: 'Número', step: 9 },
          { text: 'Complemento', step: 9 },
          { text: 'Bairro', step: 9 },
          { text: 'Cidade', step: 9 },
          { text: 'UF', step: 9 },
          { text: 'PPE', step: 9 },
          { text: 'Residente no Exterior', step: 9 },
          { text: 'Forma de Pagamento', step: 9 },
          { text: 'Banco', step: 9 },
          { text: 'Agência', step: 9 },
          { text: 'Conta', step: 9 },
          { text: 'Dígito', step: 9 },
          { text: 'Tipo de Conta', step: 9 }
        ];
    
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Quais dados deseja corrigir?', sender: 'bot' },
          ...options.map(option => ({ text: option.text, step: option.step, sender: 'button' }))
        ]);
        setStep(13); // Novo passo para escolher o dado a corrigir
      } else {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Opção inválida. Por favor, escolha uma opção válida.', sender: 'bot' }
        ]);
      }
    } else if (step === 13) {
      const selectedOption = messages.find(message => message.text === userInput);
      if (selectedOption) {
        setStep(selectedOption.step); // Volta ao passo correspondente para corrigir o dado
        setMessages(prevMessages => [
          ...prevMessages,
          { text: `Digite o novo valor para ${selectedOption.text}:`, sender: 'bot' }
        ]);
      } else {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Opção inválida. Por favor, escolha uma opção válida.', sender: 'bot' }
        ]);
      }
    }
    
    setUserInput('');
  };

  const handleNextStep = () => {
    setTimeout(() => {
      setMessages(prevMessages => [
        ...prevMessages,
        { text: 'Deseja fazer mais alguma consulta?', sender: 'bot' },
        {
          text: (
            <>
              <button onClick={() => handleOption('Sim')} style={{ marginRight: '10px' }}>
                Sim
              </button>
              <button onClick={() => handleOption('Não')}>Não</button>
            </>
          ),
          sender: 'bot'
        }
      ]);
    }, 5000);
  };

  const handleOption = (option) => {
    if (option === 'Sim') {
      setMessages(prevMessages => [
        ...prevMessages,
        { text: formatOptionsMessage(), sender: 'bot' }
      ]);
      setStep(3);
    } else if (option === 'Não') {
      setMessages(prevMessages => [
        ...prevMessages,
        { text: 'Atendimento encerrado.', sender: 'bot' }
      ]);
      setStep(1); // Reseta para o início
    }
  };

  return (
    <div>
      <ChatIconButton onClick={toggleChat} />
      {isOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <h1>Bem-vindo!</h1>
          </div>
          <div className="chat-content" ref={chatContentRef}>
            <ChatSteps messages={messages} />
          </div>
          <div className="chat-footer">
            <input
              type="text"
              placeholder="Digite sua mensagem"
              className="message-input"
              value={userInput}
              onChange={handleUserInput}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleSend();
              }}
            />
            <button className="send-button" onClick={handleSend}>
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatStructure;
